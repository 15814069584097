import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import LearningPost from '../components/learning-post'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { toPlainText } from '../lib/helpers'

export const query = graphql`
  query LearnTemplateQuery($id: String!) {
    post: sanityLearn(id: { eq: $id }) {
      id
      publishedAt
      _rawExcerpt
      provider {
        authorType
        authorName
        providerType
        providerName
      }
      courseDetails {
        startDate
        endDate
        courseMode
        courseWorkload
      }
      category
      offers {
        price
        priceCurrency
      }
      rating
      sourceLink {
        url
        cta
      }
      learnings {
        mainImage {
          ...ImageWithPreview
          caption
          alt
        }
        learnedAt
        title
        rating
        slug {
          current
        }
        _rawLearnContent(resolveReferences: { maxDepth: 5 })
      }
      title
      commitment
      status
      textColor {
        hex
      }
      bgColor {
        hex
      }
      slug {
        current
      }
      _updatedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        ...ImageWithPreview
        caption
        alt
      }
      authors {
        _key
        author {
          image {
            ...ImageWithPreview
          }
          name
        }
      }
    }
  }
`

const LearningPostTemplate = (props) => {
  const { data, errors, pageContext } = props
  const post = data && data.post

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {post && <LearningPost {...post} {...pageContext} />}
    </Layout>
  )
}

export default LearningPostTemplate