import React from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import tw, { theme, css } from 'twin.macro'

const ReviewsPortableText = ({ blocks }) => (
  <BasePortableText
  css={{
    p: tw `max-w-2xl`
  }}
    blocks={blocks}
    serializers={serializers}
    {...clientConfig.sanity}
  />
)

export default ReviewsPortableText
